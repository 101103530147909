<template>
  <div class="warp">
    <!-- 头部 -->
    <div class="warpTop">
      <div class="warpTopsCent">
        <!-- 左侧视频 -->
        <img class="warpTopsCentL" :src="video.img">

        <!-- 右侧详情 -->
        <div class="warpTopsCentR">
          <!-- 标题 -->
          <div class="title dis_flex">
            <div class="courType">录播</div>
            {{ video.title }}
          </div>
          <div class="detail-ter m-t-20 flex-c">
            <div class="ter-list">
              <div class="terbox" @click="goNext(`/faculty/teacherDetail?id=${video.teacherId}`)">
                <el-image class="ter-img" :src="video ? video.tImg : ''" fit="cover" />
              </div>
              <span class="ter">讲师：{{ video ? video.terName : '' }}</span>
            </div>
            <el-divider direction="vertical" />
            <el-rate :value="average" disabled :colors="['#99A9BF', '#F7BA2A', '#FF9900']" />
          </div>
          <div v-if="!priceShow" class="vip_box">
            <div class="content_box">
              <div class="vip_title m_r_2">课程服务</div>
              <div class="vip_content">随堂讲义/随堂资料/随堂测试</div>
            </div>
            <div class="content_box">
              <div class="vip_title em">课时</div>
              <div class="vip_content">{{ video.allTime }}课时</div>
            </div>
          </div>
          <!-- 价格 -->
          <div v-if="priceShow">
            <div class="picr">
              <div>价格</div>
              <div class="spanb">￥{{ video.jgPrice ? video.jgPrice : 0 }}</div>
              <!-- <div class="oldprice">￥{{ video.olderPrice ? video.olderPrice : 0 }}</div> -->
            </div>
            <!-- <div>
              <div>
                <span>领券</span>
                <span></span>
              </div>
              <div>
                <span>有效期</span>
              </div>
            </div> -->
            <!-- 报名资讯按钮 -->
            <div class="btn">
              <div v-if="!IsPurchase" class="keep-learn keep-check" @click="yuyue(1)">立即购买</div>
              <div v-else class="keep-learn" @click="yuyue(2)">已购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box-content">
        <!-- 选项栏 -->
        <div class="warpnav">
          <div class="warpnavs">
            <div class="warpnavsCons" :class="navIndex == 1 ? 'on' : ''" @click="navs(1)">
              <span>课程详情</span>
              <div v-if="navIndex == 1" />
            </div>

            <div class="warpnavsCons" :class="navIndex == 2 ? 'on' : ''" @click="navs(2)">
              <span>课程列表</span>
              <div v-if="navIndex == 2" />
            </div>
            <div v-if="productLine == 2 && dataShow" class="warpnavsCons" :class="navIndex == 3 ? 'on' : ''"
              @click="navs(3)">
              <span>课程资料</span>
              <div v-if="navIndex == 3" />
            </div>
            <div v-if="productLine == 2 && mkdataShow" class="warpnavsCons" :class="navIndex == 4 ? 'on' : ''"
              @click="navs(4)">
              <span>课程测试</span>
              <div v-if="navIndex == 4" />
            </div>
            <div class="warpnavsCons" :class="navIndex == 5 ? 'on' : ''" @click="navs(5)">
              <span>课程评价</span>
              <div v-if="navIndex == 5" />
            </div>
          </div>
        </div>
        <!-- 课程详情内容 -->
        <div v-if="navIndex == 1" class="warpCon">
          <div class="apple">
            <div class="appleLeft">
              <div class="appleLefts" v-html="ImgSizeLimit(video.content)" />
            </div>
            <!-- 右侧推荐 -->
            <!-- <you-love :id="zhiboId" :type="3" /> -->
          </div>
        </div>
        <!-- 录播章节 -->
        <div v-if="navIndex == 2" class="warpCon">
          <video-chater :id="zhiboId" :is-outsourcing="video.isOutsourcing" :class-id="classId ? classId : ''"
            :class-product-line="classProductLine" :is-purchase="IsPurchase ? true : video.jgPrice > 0 ? false : true"
            :list="video.list" :is-freeze="isFreeze" />
        </div>
        <!-- 课程资料 -->
        <div v-if="navIndex == 3" class="warpCon">
          <course-data v-if="navIndex == 3" :id="zhiboId" :type="2" :is-purchase="IsPurchase" />
        </div>
        <!-- 课程模考 -->
        <div v-if="navIndex == 4" class="warpCon">
          <course-task v-if="navIndex == 4" :id="zhiboId" :type="2" :is-purchase="IsPurchase" />
        </div>
        <!-- 课程评价 -->
        <div v-if="navIndex == 5" class="warpCon">
          <score-evaluation v-if="navIndex == 5" :type="3" :type-id="zhiboId" :type-nmae="video.title"
            :is-purchase="IsPurchase" :course-data="video" />
        </div>
      </div>
      <you-love :id="zhiboId" class="youlove" :ifteacher="true" :type="3" />
    </div>

    <div v-if="isFreeze == 1" class="mask_layer">课程已冻结，请联系老师</div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { checkStudyCode } from '@/api/home.js'
import { getClassCourseById } from '@/api/home'
import VideoChater from '@/components/Know/videoChater.vue'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { getToken } from '@/api/cookies'
import YouLove from '@/components/Know/youLove.vue'
import CourseData from '@/components/Know/course/courseData.vue'
import courseTask from '@/components/Know/course/courseTask.vue'
import scoreEvaluation from '@/components/Know/course/scoreEvaluationPartner.vue'
import { Evaluation } from '@/api/scoreEvaluation'
const evaluation = new Evaluation()
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { VideoChater, YouLove, CourseData, courseTask, scoreEvaluation },

  data() {
    return {
      video: {},
      navIndex: 2,
      yuyues: 2,
      IsPurchase: false, // 章节列表
      zhiboId: null,
      classId: null,
      average: 0, // 评分
      productLine: null,
      cardCode: null,
      classProductLine: 0,
      isFreeze: null, // 是否冻结 0 否 1 是
      mkdataShow: true,
      dataShow: true,

      priceShow: true
    }
  },
  created() {
    this.classProductLine = this.$route.query.classProductLine
      ? Number(this.$route.query.classProductLine)
      : 0
    this.cardCode = this.$route.query.cardCode == 'undefined' ? null : this.$route.query.cardCode
    this.checkStudyCode()
    this.classId =
      this.$route.query.classId &&
        this.$route.query.classId != 'undefined' &&
        this.$route.query.classId != 'null' &&
        Number(this.$route.query.classId) != NaN
        ? this.$route.query.classId
        : null
    this.zhiboId = this.$route.query.id
    this.loadgetDetail()
    this.getLiveData()

    evaluation.getPresentScore(3, this.zhiboId).then((res) => {
      this.average = Math.round(res.average)
    })
    know.updateCourseAllClicks(3, this.zhiboId)

    this.priceShow = this.$route.query.vip != 'true'
  },
  methods: {
    getLiveData() {
      know.getCourseContactButton({ courseId: this.zhiboId, courseType: 2 }).then((res) => {
        this.mkdataShow = res.data
      })
      know.getDocButton({ courseId: this.zhiboId, courseType: 2 }).then((res) => {
        this.dataShow = res.data
      })
    },
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode
        }
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true
            this.$store.commit('IS_PURCHASE_CHANGE', true)
          }
        })
      }
    },
    // 录播课程详情信息
    loadgetDetail() {
      getClassCourseById(this.zhiboId, this.classId).then((res) => {
        if (!this.cardCode) {
          this.IsPurchase = res.data.IsPurchase
        }
        this.productLine = res.data.details.productLine
        this.video = res.data.details
        this.video.size = res.data.size
        this.video.price = res.data.details.jgPrice
        this.video.jgPrice = res.data.jgPrice
        this.isFreeze = res.data.isFreeze
        // this.productLine = res.data.details.productLine;
      })
    },
    // 预约按钮
    yuyue(index) {
      this.yuyues = index
      if (index == 1) {
        const token = getToken()
        if (token) {
          orderClient
            .addOrders(
              this.video.id,
              localStorage.getItem('userId'),
              this.video.title,
              3,
              this.video.title,
              this.video.kind
            )
            .then((res) => {
              if (res.code == 0) {
                /*   this.users = res.msg; */
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          Vue.prototype.goLoginView(true)
          // this.$router.push({ name: "登录页", params: { hzc: 1 } });
        }
      }
    },
    // 立即录播按钮
    zhiboBut() {
      const token = getToken()
      if (token) {
        this.$router.push({
          name: '录播课播放',
          params: {
            id: this.video.id,
            ccLiveid: this.video.ccLiveid,
            ccRomid: this.video.ccRomid,
            recordId: this.video.recordId,
            videos: this.video.aliVideoUrl,
            titles: this.video.title
          }
        })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val
    },
    // 观看重播按钮  切换课程章节播放
    gkcb(v) {
      this.$router.replace(`/zhibobfang?id=${this.$route.params.id}&ids=${v.id}&typebs=1`)
    }
  }
}
</script>
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  overflow: hidden;

  // 头部
  .warpTop {
    width: 100%;
    background: #ffffff;
    padding: 40px calc(50% - 610px);

    .warpTopsCent {
      width: 1220px;
      height: 225px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // 左侧视频
      .warpTopsCentL {
        width: 338px;
        height: 225px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-right: 28px;
        flex-shrink: 0;
      }

      // 右侧详情
      .warpTopsCentR {
        width: 100%;
        height: 100%;
        position: relative;

        // 标题
        .title {
          font-size: 24px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;

          .courType {
            height: 24px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #ff7b1c;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 22px;
            padding: 0px 11px;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }

        .vip_box {
          margin-top: 24px;

          .content_box {
            display: flex;
            margin-bottom: 24px;

            .vip_title {
              // margin-right: 16px;
              flex-shrink: 0;
              flex-grow: 0;
              color: #666666;
              font-size: 14px;
            }

            .vip_content {
              color: #333333;
              font-size: 14px;
            }

            .m_r_2 {
              margin-right: 2em;
            }

            .em {
              letter-spacing: 2em;
            }
          }
        }

        // 价格
        .picr {
          width: 360px;
          height: 54px;
          line-height: 54px;
          background: #f5f7f9;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
          margin-top: 20px;

          .spanb {
            font-size: 24px;
            color: #ff5e51;
            margin: 0px 8px;
          }
        }

        // 报名资讯按钮
        .btn {
          width: auto;
          height: 46px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          bottom: 0px;

          // 立即报名
          .keep-learn {
            height: 40px;
            padding: 0px 28px;
            line-height: 38px;
            font-size: 16px;
          }
        }
      }
    }
  }

  // 选项栏
  .warpnav {
    width: 100%;
    margin: 0px auto;
    height: 66px;
    background: #ffffff;
    border-bottom: 1px solid #dddddd;
    padding: 0px 32px;
    line-height: 66px;

    // 缩宽
    .warpnavs {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // 知识套餐概述
      /*  .warpnavsCons {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      } */
      // 竖线

      // 转换内容 选中
      .on {
        height: 100%;

        span {
          font-weight: bold !important;
          color: #333333;
        }

        div {
          width: 30px;
          height: 3px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          margin-left: calc(50% - 15px);
          margin-top: -3px;
        }
      }

      // 转换内容 未选中
      .warpnavsCons {
        height: 100%;
        margin-right: 60px;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
      }
    }
  }

  // 直播课内容
  .warpCon {
    width: 100%;
    margin: 0px auto;
    background: #fff;

    // 缩宽
    .apple {
      width: 1200px;
      height: 100%;
      /* margin: 30px auto; */
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .appleLeft {
        width: 750px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .appleLefts/deep/img {
          width: 700px;
          height: auto;
          margin-top: 25px;
        }
      }

      .appleRight {
        width: 430px;
        height: 1292px;
        background: #ffffff;

        .appleRightTop {
          width: auto;
          height: 20px;
          margin: 16px 22px;
          border-left: 6px solid #3855f9;
          padding: 0px 12px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e7e7e;
        }

        img {
          width: 376px;
          height: 209px;
          margin-top: 25px;
          margin-left: 27px;
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}

.content {
  display: flex;
  width: 1220px;
  margin: 20px calc(50% - 610px);

  .box-content {
    width: 880px;
    margin-right: 20px;
  }

  .youlove {
    width: 320px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0px;
  }
}

.el-popover {
  overflow: hidden;
  padding: 0px;
}

.mask_layer {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terbox {
  cursor: pointer;
}
</style>
